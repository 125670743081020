
import React from 'react'
import "./About.css"
import ME from "../../assets/me123.jpg"
import { FaAward } from "react-icons/fa"
import { GiGraduateCap } from "react-icons/gi"
import { VscFolderActive } from "react-icons/vsc"
import 'animate.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';


const About = () => {
  return (
    <section id='about'>

      <h5 className='getToKnow'>Get To Know</h5>

      <h2 className='abtme'>About Me</h2>

      <div className="container about__container">


        <AnimationOnScroll animateIn="animate__fadeInLeft">
          <div className="about__me">
            <div className="about__me-image">
              <img src={ME} alt="" />
            </div>
          </div>
        </AnimationOnScroll>

        <div className="about__content">
          <div className="about__cards">
          <div className="blur blur-a"></div>

            <AnimationOnScroll animateIn="animate__fadeInRight">
              <article className="about__card">
                <FaAward className='about-icon' />
                <h5>Experience</h5>
                <small> 1+ Year of Working </small>
              </article>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInRight" delay={400}>
              <a href="#education" style={{ color: "white" }}>
                <article className="about__card">
                  <GiGraduateCap className='about-icon' />
                  <h5>Education</h5>
                  <small> Bachelor's Degree In Computer Science </small>
                </article>
              </a>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInRight" delay={600}>
              <a href="#Portfolio" style={{ color: "white" }}>
                <article className="about__card">
                  <VscFolderActive className='about-icon' />
                  <h5>Projects</h5>
                  <small> 10+ Completed </small>
                </article>
              </a>
            </AnimationOnScroll>

          </div>

          <AnimationOnScroll animateIn="animate__fadeIn" delay={200}>
            <p>I am a full stack web developer with robust problem-solving skills and proven experience in designing, developing and deploying software, website & web-application in a test-driven environment.
            </p>
          </AnimationOnScroll>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>

      </div>
    </section>

  )
}

export default About
