import React from 'react'
import "./Header.css"
import CTA from "./CTA"
import ME from "../../assets/me.png"
import Coder from "../../assets/coder.png"
import HeaderSocials from './HeaderSocials'
import TypeWriterEffect from 'react-typewriter-effect';

function Header() {
  return (
    <header className='headerbackground'>
      <div className=" header__container">
        <h5>Hello I'm</h5>
      <div className="blur blur-h"></div>
        <h1>Arjun Samrat</h1>
        <h5 className='text-light'>Fullstack Web Developer</h5>

        <span className='ideas'>
          <TypeWriterEffect
          textStyle={{
            fontSize: '1em',
            textAlign:"center"
          }}
            
            startDelay={100}
            cursorColor="white"
            text="Bringing Ideas to real life products..."
            typeSpeed={80}
          />
        </span>
        <CTA />
        <HeaderSocials />
        <div className='me'>
          {/* <img src={ME} alt="me" /> */}
          <span className='coder'>
            <lottie-player src="https://assets1.lottiefiles.com/private_files/lf30_obidsi0t.json" background="transparent" speed="1" loop autoplay></lottie-player>
          </span>
        </div>


        <a href="#footer" className="scroll__down">Scroll Down</a>


      </div>

    </header>
  )
}

export default Header
